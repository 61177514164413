import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';
import ModuleHead from '../../components/moduleHead';

class Storm extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染组件
     */
    render() {
        const { clickRankHead, clickRankData } = this.props.content;

        return (
            <div className={styles.clickRank}>
                <Chip id="260644" type="struct" title="点击排行标题" groupName="点击排行" content={clickRankHead}>
                    <ModuleHead />
                </Chip>
                <div className={styles.clickRankBox}>
                    {clickRankData.slice(0, 10).map((item, index) => {
                        return (
                            <a href={item.url} key={index} className={styles.clickRankItem}>
                                <span className={styles.clickRankNum}>{index + 1}</span>
                                <span className={styles.clickRankTitle}>{item.title}</span>
                            </a>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default errorBoundary(Storm);
