import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Chip from 'Chip';
import Ad from '@ifeng-fe/ui_pc_ad';
import ModuleHead from '../../components/moduleHead';
import { getClsData, getFinanceStructData } from '@src/services/api';

class Live extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    state = {
        liveData: [],
        lastid: '',
        insertData: [],
    };

    componentDidMount() {
        this.getLiveData();
    }

    combineInsertData = (clsData, insertData) => {
        const list = [...clsData, ...insertData].sort((a, b) => {
            return parseInt(b.ctime) - parseInt(a.ctime);
        });

        return list.slice(0, 6);
    };

    getFormatTime = (num, fmt) => {
        const time = new Date(num * 1000);
        const o = {
            'M+': time.getMonth() + 1,
            'd+': time.getDate(),
            'h+': time.getHours(),
            'm+': time.getMinutes(),
            's+': time.getSeconds(),
            'q+': Math.floor((time.getMonth() + 3) / 3),
            S: time.getMilliseconds(),
        };

        if (/(y+)/.test(fmt)) {
            fmt = fmt.replace(RegExp.$1, `${time.getFullYear()}`.substr(4 - RegExp.$1.length));
        }

        for (const k in o) {
            if (new RegExp(`(${k})`).test(fmt)) {
                fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : `00${o[k]}`.substr(`${o[k]}`.length));
            }
        }

        return fmt;
    };

    getLiveData = async () => {
        try {
            const data = await getClsData(Date.parse(new Date()));

            if (data.data && data.data.length > 0) {
                const start = data.data[data.data.length - 1].ctime;
                const insertData = await this.getInsertData(start);

                this.setState({
                    liveData: data.data.slice(0, 30),
                    lastid: data.data[0].id,
                    insertData,
                });
            }
        } catch (e) {
            console.error(e);
        }
    };

    getInsertData = async start => {
        const res = await getFinanceStructData();

        const insertData =
            res && Array.isArray(res)
                ? res
                      .map(item => {
                          const { time, _brief, title, url } = item;

                          const ctime = (new Date(time.replace(/-/g, '/')).getTime() / 1000).toString();

                          return { brief: title || _brief, ctime, url };
                      })
                      .filter(item => {
                          return parseInt(item.ctime) > parseInt(start);
                      })
                : [];

        return insertData;
    };

    getTitle = title => {
        if (title.indexOf('<div>') > -1) {
            title = title.substring(5, title.length);
        }

        const indexAfDiv = title.indexOf('</div>');

        if (indexAfDiv > -1) {
            title = title.substring(0, indexAfDiv);
        }

        return title;
    };

    createMarkup = title => {
        return { __html: this.getTitle(title) };
    };

    /**
     * 渲染组件
     */
    render() {
        const { liveHead, centerAd1 } = this.props.content;
        const { liveData, insertData } = this.state;

        return (
            <div className={styles.live}>
                <Chip id="260635" type="struct" title="直播室标题" groupName="直播室" content={liveHead}>
                    <ModuleHead />
                </Chip>
                <div className={styles.liveList}>
                    {this.combineInsertData(liveData, insertData).map((item, index) => (
                        <div key={index} className={styles.liveItem}>
                            <div className={styles.liveTime}>{this.getFormatTime(item.ctime, 'hh:mm:ss')}</div>
                            {item.url ? (
                                <div className={styles.liveText}>{item.brief}</div>
                            ) : (
                                <div
                                    className={styles.liveText}
                                    dangerouslySetInnerHTML={this.createMarkup(item.brief)}
                                />
                            )}
                        </div>
                    ))}
                    <Ad content={centerAd1} />
                </div>
            </div>
        );
    }
}

export default errorBoundary(Live);
