import React from 'react';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import Nav from './nav/';

class Navigation extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;

        return (
            <Chip id="20008" type="struct" title="财经导航" groupName="导航栏" content={content.nav}>
                <Nav content={content.nav} />
            </Chip>
        );
    }
}

export default Navigation;
