import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';
import ModuleHead from '../../components/moduleHead';

class Focus extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染组件
     */
    render() {
        const { focusHead, focusImageData, focusTextData } = this.props.content;

        return (
            <div className={styles.focus}>
                <Chip id="260645" type="struct" title="专题会议标题" groupName="专题会议" content={focusHead}>
                    <ModuleHead />
                </Chip>
                <div className={styles.focusBox}>
                    <Chip id="260652" type="struct" title="专题会议图片列表" groupName="专题会议">
                        <div>
                            {focusImageData.map((item, index) => {
                                return (
                                    <a href={item.url} target="_blank" className={styles.focusPic} key={index}>
                                        <img className={styles.focusImg} src={item.thumbnail} />
                                    </a>
                                );
                            })}
                        </div>
                    </Chip>
                    <Chip id="287896" type="recommend" title="专题会议文字列表" groupName="专题会议">
                        <div>
                            {focusTextData.map((item, index) => {
                                return (
                                    <a href={item.url} target="_blank" className={styles.focusText} key={index}>
                                        {item.title}
                                    </a>
                                );
                            })}
                        </div>
                    </Chip>
                </div>
            </div>
        );
    }
}

export default errorBoundary(Focus);
