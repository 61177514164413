import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';
import ModuleHead from '../../components/moduleHead';
import ModuleFeed from '../../components/moduleFeed';

class Storm extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const { stormHead, stormBref, stormData } = this.props.content;

        return (
            <div className={styles.news}>
                <Chip id="260636" type="struct" title="风暴眼标题" groupName="风暴眼" content={stormHead}>
                    <ModuleHead content={stormHead} />
                </Chip>
                <ModuleFeed bref={stormBref} lists={stormData} title="风暴眼" brefId="260647" />
            </div>
        );
    }
}

export default errorBoundary(Storm);
