import React from 'react';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import { formatImage } from '@ifeng-fe/public_method';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import ModuleHead from '../../components/moduleHead';

class Featured extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染组件
     */
    render() {
        const { featuredHead, featuredData } = this.props.content;

        return (
            <div className={styles.featured}>
                <Chip id="260643" type="struct" title="特色专栏标题" groupName="特色专栏" content={featuredHead}>
                    <ModuleHead />
                </Chip>
                <Chip id="260651" type="struct" title="特色专栏数据列表" groupName="特色专栏">
                    <div className={styles.featuredBox}>
                        {featuredData.map((item, index) => {
                            return (
                                <a key={index} href={item.url} target="_blank" className={styles.featuredLink}>
                                    <div className={styles.featuredTitle}>{item.title}</div>
                                    <div className={styles.featuredPicBox}>
                                        <img className={styles.featuredPic} src={formatImage(item.thumbnail, 300)} />
                                    </div>
                                </a>
                            );
                        })}
                    </div>
                </Chip>
            </div>
        );
    }
}

export default errorBoundary(Featured);
