import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';

class ModuleFeed extends React.PureComponent {
    static propTypes = {
        bref: PropTypes.object,
        lists: PropTypes.array,
        title: PropTypes.string,
        brefId: PropTypes.string,
    };

    /**
     * 渲染组件
     */
    render() {
        const { bref, lists, title, brefId } = this.props;
        const brefTitle = `${title}简介`;

        return (
            <div className={styles.moduleFeed}>
                <Chip id={brefId} type="struct" title={brefTitle} groupName={title}>
                    {bref.title && (
                        <a href={bref.url} target="_blank" className={styles.moduleFeedFirst}>
                            <div className={styles.moduleFeedPicBox}>
                                <img className={styles.moduleFeedPic} src={bref.thumbnail} />
                            </div>
                            <div className={styles.moduleFeedFirstText}>
                                <div className={styles.moduleFeedFirstTitle}>{bref.title}</div>
                                <div className={styles.moduleFeedFirstBref}>{bref.bref}</div>
                            </div>
                        </a>
                    )}
                </Chip>
                {lists.map((item, index) => (
                    <a
                        key={index}
                        href={item.url}
                        target="_blank"
                        className={`${styles.moduleFeedTitle} ${index === 0 ? styles.moduleFeedTitleBold : ''}`}>
                        {item.title}
                    </a>
                ))}
            </div>
        );
    }
}

export default errorBoundary(ModuleFeed);
