import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';
import ModuleHead from '../../components/moduleHead';
import ModuleFeed from '../../components/moduleFeed';

class BankEye extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染组件
     */
    render() {
        const { bankEyeHead, bankEyeBref, bankEyeData } = this.props.content;

        return (
            <div className={styles.news}>
                <Chip id="260637" type="struct" title="银行财眼标题" groupName="银行财眼" content={bankEyeHead}>
                    <ModuleHead />
                </Chip>
                <ModuleFeed bref={bankEyeBref} lists={bankEyeData} title="银行财眼" brefId="260648" />
            </div>
        );
    }
}

export default errorBoundary(BankEye);
