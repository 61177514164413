import React from 'react';
import NoSSR from 'react-no-ssr';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import Ad from '@ifeng-fe/ui_pc_ad';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { rel } from '@ifeng-fe/container_channel/common/client/utils/rel';

/**
 * 定义 Logo 组件
 */
class Logo extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染组件
     */
    render() {
        const {
            content: { logo, channelAd },
        } = this.props;

        return (
            <React.Fragment>
                <a href={logo.url} target="_blank" rel={rel}>
                    <Chip id="170191" type="struct" title="Logo" groupName="头部" content={logo}>
                        <img src={logo.src} alt={logo.title} width={logo.width} height={logo.height} />
                    </Chip>
                </a>
                <img className={styles.logo_2} src="//p1.ifengimg.com/a/2016_23/26a9c07a77f503f.jpg" />
                <NoSSR onSSR={<div className={styles.ad_2} />}>
                    <Ad content={channelAd} />
                </NoSSR>
            </React.Fragment>
        );
    }
}

export default errorBoundary(Logo);
