const md5 = require('md5');

/**
 * 计算文章评论 skey
 * @param {string} title 文章标题
 * @param {string} pcUrl 文章 pcUrl
 */
export const getSkey = (title, pcUrl) => {
    const str = `Ifeng888${encodeURI(title)}${encodeURI(pcUrl)}`;
    const skey = md5(str);

    return skey.substr(2, 6).toLowerCase();
};

/**
 * 处理新闻时间
 * @param {string} time 新闻时间
 */
export const handleNewstime = time => {
    try {
        if (!time) {
            return time;
        }

        const d = new Date();

        time = time.substr(0, time.length - 3);
        const year = Number(time.split('-')[0]);

        if (year < d.getFullYear()) {
            return time;
        } else {
            return time.substr(5, time.length);
        }
    } catch (e) {
        console.error(e);

        return time;
    }
};

// 获取URL查询参数
export const getQuery = (url, key) => {
    let value = '';

    try {
        const urlObj = new URL(url);
        const { searchParams } = urlObj;

        if (searchParams.has(key)) {
            value = searchParams.get(key);
        }
    } catch (e) {
        console.error(e);
    }

    return value;
};
