import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Chip from 'Chip';
import { formatImage } from '@ifeng-fe/public_method';
import ModuleHead from '../../components/moduleHead';
import { getHotEvent } from '@src/services/api';

class HotList extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    state = {
        hotEvent: [],
    };

    componentDidMount() {
        try {
            getHotEvent(360874, 3).then(res => {
                const list = res?.list || [];

                this.setState({
                    hotEvent: list?.slice(0, 5) || [],
                });
            });
        } catch (e) {
            console.log(e, '获取热榜数据失败');
        }
    }

    /**
     * 渲染组件
     */
    render() {
        const { hotListHead, hotListMore } = this.props.content;
        const { hotEvent } = this.state;

        return (
            <div className={styles.hotList}>
                <Chip id="260641" type="struct" title="热榜标题" groupName="热榜" content={hotListHead}>
                    <ModuleHead />
                </Chip>
                <div className={styles.hotListBox}>
                    {hotEvent.map((item, index) => {
                        return (
                            <div key={index} className={styles.hotListItem}>
                                <a href={item.url} target="_blank" className={styles.hotListTopic}>
                                    <span className={styles.hotListTopicTitle}>#{item.title}</span>
                                    <span className={styles.hotListTopicNum}>&nbsp;</span>
                                </a>
                                <a href={item?.child?.url} target="_blank" className={styles.hotListFeed}>
                                    {item?.child?.thumbnail && (
                                        <div className={styles.hotListFeedPicBox}>
                                            <img
                                                className={styles.hotListFeedPic}
                                                src={formatImage(item.child.thumbnail, 120, 67)}
                                                alt=""
                                            />
                                        </div>
                                    )}
                                    <div className={styles.hotListFeedTitle}>{item?.child?.title}</div>
                                </a>
                            </div>
                        );
                    })}
                </div>
                <Chip id="260654" type="struct" title="热榜查看更多" groupName="热榜">
                    <a href={hotListMore.moreUrl} target="_blank" className={styles.hotListMore}>
                        查看更多
                    </a>
                </Chip>
            </div>
        );
    }
}

export default errorBoundary(HotList);
