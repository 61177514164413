import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';
import ModuleHead from '../../components/moduleHead';
import ModuleFeed from '../../components/moduleFeed';

class Newsflash extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染组件
     */
    render() {
        const { newsflashHead, newsflashData } = this.props.content;

        return (
            <div className={styles.hotspots}>
                <Chip id="260808" type="struct" title="监管快报标题" groupName="放眼全球" content={newsflashHead}>
                    <ModuleHead />
                </Chip>
                <div className={styles.hotspotsList}>
                    {newsflashData.map((item, index) => {
                        return (
                            <a href={item.url} key={index} target="_blank" className={styles.hotspotsItem}>
                                {item.title}
                            </a>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default errorBoundary(Newsflash);
