import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import { Event } from '@ifeng-fe/ui_base';
import { handleAd } from '@ifeng-fe/ui_pc_ad';
import errorBoundary from '@ifeng-fe/errorBoundary';
import ModuleHead from '../../components/moduleHead';
import { formatImage } from '@ifeng-fe/public_method';
import { getCommentCount } from '@src/services/api';
// import BannerPic from '../../components/bannerPic';

class News extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    state = {
        commentCount: {},
        newsListData: this.props.content.newsData || [],
    };

    event = new Event();

    async componentDidMount() {
        const { commentCount } = this.state;
        const { newsData = [], leftAd2 } = this.props.content;

        const docUrl = newsData.map(item => item.commentUrl);

        try {
            // 获取评论数
            const counts = await getCommentCount(docUrl);

            counts.forEach(item => {
                commentCount[item.doc_url] = item.allcount;
            });

            this.setState({
                commentCount: { ...commentCount },
            });

            // 插入广告
            if (leftAd2) {
                try {
                    const callback = await handleAd(leftAd2);

                    callback(leftAd2.data, this.event, this.insert);
                } catch (e) {
                    console.error(e);
                }
                try {
                    this.event.trigger('init');
                } catch (e) {
                    console.error(e);
                }
            }
        } catch (e) {
            console.error(e);
        }
    }

    insert = (insertArr, replaceArr, adFn) => {
        if (adFn && typeof adFn === 'function') {
            const { newsData } = this.props.content;

            const infoData = [...newsData];
            const refs = [];

            insertArr.forEach(item => {
                const ref = React.createRef();

                refs.push({ index: item, ref });

                infoData.splice(item, 0, { type: 'ad', ref });
            });

            replaceArr.forEach(item => {
                const ref = React.createRef();

                refs.push({ index: item, ref });

                infoData.splice(item, 1, { type: 'ad', ref });
            });

            this.setState(
                {
                    newsListData: infoData,
                },
                () => {
                    const dom = {};

                    for (const ref of refs) {
                        if (ref?.ref?.current) {
                            dom[ref.index] = ref.ref.current;
                        }
                    }

                    adFn(dom);
                },
            );
        }
    };

    /**
     * 渲染组件
     */
    render() {
        const { commentCount, newsListData } = this.state;
        const { newsHead } = this.props.content;

        return (
            <div className={styles.news}>
                <Chip id="260634" type="struct" title="财经要闻标题" groupName="财经要闻" content={newsHead}>
                    <ModuleHead />
                </Chip>
                <div className={styles.newsContent}>
                    {newsListData.map((item, index) => {
                        const thumbnail = item?.thumbnail || '';
                        const commentNum = commentCount?.[item?.commentUrl] || 0;

                        if (item.type === 'ad') {
                            return <div key={`ad_${index}`} ref={item.ref} className={styles.newsFeed} />;
                        }

                        return (
                            <a href={item.url} key={index} target="_blank" className={styles.newsFeed}>
                                {thumbnail && (
                                    <div className={styles.newsFeedImgBox}>
                                        <img className={styles.newsFeedImg} src={formatImage(thumbnail, 175, 98)} />
                                    </div>
                                )}
                                <div className={styles.newsFeedText}>
                                    <div className={styles.newsFeedTitle}>{item.title}</div>
                                    <div className={styles.newsFeedInfo}>
                                        <span className={styles.newsFeedTime}>{item.newsTime.substring(5, 16)}</span>
                                        <span className={styles.newsFeedComment}>{commentNum}评</span>
                                    </div>
                                </div>
                            </a>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default errorBoundary(News);
