import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';

const StockList = ({ allMarket }) => {
    const stockNameList = [
        '美元指数',
        '标普500指数',
        '道琼斯工业平均指数',
        '上证指数',
        '香港恒生指数',
        '现货黄金',
        '现货白银',
        'WTI原油',
        '布伦特油',
        '离岸人民币',
    ];
    // 获取股票列表
    const stockList = allMarket.filter(item => stockNameList.includes(item.name));

    // stockList数据按照stockNameList内容进行排序
    stockList.sort((a, b) => {
        return stockNameList.indexOf(a.name) - stockNameList.indexOf(b.name);
    });

    return (
        <div className={styles.stockList}>
            {/* styles.marketRed styles.marketGreen */}
            {stockList.map((item, index) => {
                // 判断涨跌
                let statusFlag = item.rangePercent.includes('-') ? 'down' : 'up';
                const statusClassList = {
                    down: styles.marketGreen,
                    up: styles.marketRed,
                    flat: styles.marketGray,
                };

                statusFlag = item.rangePercent.includes('--') ? 'flat' : statusFlag;

                return (
                    <a
                        href={`https://finance.ifeng.com/marketDetail?quoteCode=${encodeURIComponent(item.quoteCode)}`}
                        key={index}
                        target="_blank"
                        className={`${styles.marketItem} ${statusClassList[statusFlag]}`}>
                        <div className={styles.marketItemBox}>
                            <div className={styles.marketName}>{item.name}</div>
                            <div className={styles.marketPrice}>{item.tradePrice}</div>
                            <div className={styles.marketRange}>
                                <span className={styles.marketRangeNum}>
                                    {statusFlag === 'up' ? `+${item.range}` : item.range}
                                </span>
                                <span className={styles.marketRangeNum}>
                                    {statusFlag === 'up' ? `+${item.rangePercent}` : item.rangePercent}
                                </span>
                            </div>
                        </div>
                    </a>
                );
            })}
        </div>
    );
};

StockList.propTypes = {
    allMarket: PropTypes.array,
};

export default errorBoundary(StockList);
