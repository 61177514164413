import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import ModuleHead from '../../components/moduleHead';
import { getStockTime } from '@src/services/api';
import * as echarts from 'echarts';

let timer = null;
const Stocks = ({ marketVarieties, allMarket }) => {
    const [stockIndex, setStockIndex] = useState(0);
    const [stockTime, setStockTime] = useState({});
    const marketData = marketVarieties?.['股指'] || [];
    const marketQuoteCodes = marketData.slice(0, 5).map(item => item.quoteCode);
    const stockList = allMarket.filter(item => marketQuoteCodes.includes(item.quoteCode));
    const canvasRef = useRef(null);
    let myChart = null;

    // stockList按照marketQuoteCodes中quoteCode值进行排序
    stockList.sort((a, b) => {
        const indexA = marketQuoteCodes.indexOf(a.quoteCode);
        const indexB = marketQuoteCodes.indexOf(b.quoteCode);

        return indexA - indexB;
    });

    const handleMouseEnter = index => {
        setStockIndex(index);
    };

    const creatArrayList = (allTimeDot, addDotNum) => {
        const dataList = allTimeDot.map(item => item?.tm || '');

        for (let i = 0; i < addDotNum; i++) {
            dataList.push('');
        }

        return dataList;
    };

    const canvasHandle = (allTimeDot, echartsInfo) => {
        const echartsType = echartsInfo?.xAxis_type || 'time';

        myChart = echarts.init(canvasRef.current);
        // 计算数据列表allTimeDot最小值和最大值
        const echartsData = allTimeDot.map(item => [item.tm, item.close]);
        const addDotNum = echartsInfo?.lackNum || 0;
        const timeEmptyList = creatArrayList(allTimeDot, addDotNum);
        const optionxAxisData = echartsType === 'time' ? echartsData : timeEmptyList;

        const option = {
            grid: {
                left: '0',
                right: '0%',
                top: '15%',
                bottom: '10%',
            },
            xAxis: {
                type: echartsType,
                data: optionxAxisData,
                boundaryGap: false,
                axisTick: {
                    show: false,
                },
                axisLabel: {
                    show: false,
                },
                axisLine: {
                    lineStyle: {
                        color: '#999999', // 设置 X 轴的颜色为红色
                    },
                },
                max: echartsInfo?.startAndEndTime?.fullEndTime,
            },
            yAxis: {
                type: 'value',
                splitLine: {
                    show: false,
                },
                axisLabel: {
                    inside: true, // 刻度标签在网格区域内部显示
                    position: 'inside',
                    verticalAlign: 'bottom',
                    margin: 0,
                },
                splitNumber: 2,
                show: true,
                scale: true,
            },
            series: [
                {
                    type: 'line',
                    showSymbol: false,
                    smooth: true,
                    areaStyle: {
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [
                                {
                                    offset: 0,
                                    color: '#608AE3', // 0% 处的颜色
                                },
                                {
                                    offset: 1,
                                    color: '#ffffff', // 100% 处的颜色
                                },
                            ],
                            global: false, // 缺省为 false
                        },
                    },
                    data: echartsData,
                    lineStyle: {
                        color: '#608AE3',
                        width: 0.5,
                    },
                },
            ],
            tooltip: {
                trigger: 'axis',
            },
        };

        myChart.setOption(option);

        window.addEventListener('resize', () => {
            myChart.resize({
                width: canvasRef.current.clientWidth,
                height: canvasRef.current.clientHeight,
            });
        });
    };

    const getStockTimeHandle = async () => {
        try {
            const liveQuoteCode = stockList[stockIndex].quoteCode;
            const stockTime = await getStockTime(liveQuoteCode);
            const allTimeDot = stockTime?.quoteTSDList || [];
            const echartInfo = stockTime?.echarts || {};

            setStockTime({
                startTime: echartInfo?.startAndEndTime?.startTime || '',
                endTime: echartInfo?.startAndEndTime?.endTime || '',
            });
            canvasHandle(allTimeDot, stockTime?.echarts);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(
        () => {
            clearInterval(timer);
            getStockTimeHandle();
            timer = setInterval(() => {
                getStockTimeHandle();
            }, 60 * 1000);

            return () => {
                clearInterval(timer);
            };
        },
        [stockIndex],
    );

    return (
        <div className={styles.stocksBox}>
            <ModuleHead
                content={{
                    zhText: '股票',
                }}
            />
            <div className={styles.stocks}>
                <div className={styles.columnBdCanvasBox}>
                    <div className={styles.columnBdCanvas} ref={canvasRef} />
                    <div className={styles.columnBdStartTime}>{stockTime?.startTime || ''}</div>
                    <div className={styles.columnBdEndTime}>{stockTime?.endTime || ''}</div>
                </div>
                <div className={styles.columnBdBox}>
                    <table className={styles.columnBdTable}>
                        <tbody>
                            {stockList.map((item, index) => {
                                let statusFlag = item.rangePercent.includes('-') ? 'down' : 'up';
                                const statusClassList = {
                                    down: styles.columnBdTableGreen,
                                    up: styles.columnBdTableRed,
                                    flat: styles.columnBdTableGray,
                                };

                                statusFlag = item.rangePercent.includes('--') ? 'flat' : statusFlag;

                                return (
                                    <tr
                                        key={index}
                                        onMouseEnter={() => {
                                            handleMouseEnter(index);
                                        }}
                                        className={`${styles.columnBdTableTr} ${
                                            stockIndex === index ? styles.columnBdTableActive : ''
                                        }`}>
                                        <td className={styles.columnBdTableTd}>
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={`https://finance.ifeng.com/marketDetail?quoteCode=${encodeURIComponent(
                                                    item.quoteCode,
                                                )}`}>
                                                {item.name}
                                            </a>
                                        </td>
                                        <td className={styles.columnBdTableTd}>{item.tradePrice}</td>
                                        <td className={styles.columnBdTableTd}>
                                            <span className={statusClassList[statusFlag]}>
                                                {statusFlag === 'up' ? `+${item.range}` : item.range}
                                            </span>
                                        </td>
                                        <td className={styles.columnBdTableTd}>
                                            <span className={statusClassList[statusFlag]}>
                                                {statusFlag === 'up' ? `+${item.rangePercent}` : item.rangePercent}
                                            </span>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

Stocks.propTypes = {
    marketVarieties: PropTypes.object,
    allMarket: PropTypes.array,
};

export default errorBoundary(Stocks);
