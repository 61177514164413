import React from 'react';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import { formatImage } from '@ifeng-fe/public_method';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import ModuleHead from '../../components/moduleHead';

class Insight extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染组件
     */
    render() {
        const { insightHead, insightFirstData, insightData, insightMore } = this.props.content;

        return (
            <div className={styles.news}>
                <Chip id="260642" type="struct" title="财律洞察标题" groupName="财律洞察" content={insightHead}>
                    <ModuleHead />
                </Chip>
                <div className={styles.insightBox}>
                    <Chip id="260650" type="struct" title="财律洞察首条数据" groupName="财律洞察">
                        <div className={styles.firstData}>
                            <a href={insightFirstData.url} target="_blank" className={styles.firstDataFeed}>
                                <div className={styles.firstDataFeedPicBox}>
                                    <img
                                        className={styles.firstDataFeedPic}
                                        src={formatImage(insightFirstData.faceImg, 120, 160)}
                                    />
                                </div>
                                <div className={styles.firstDataFeedText}>
                                    <div className={styles.firstDataFeedName}>{insightFirstData.name}</div>
                                    <div className={styles.firstDataFeedBref}>{insightFirstData.bref}</div>
                                </div>
                            </a>
                            <a href={insightFirstData.url} target="_blank" className={styles.firstDataInfo}>
                                <span className={styles.firstDataMarkL} />
                                <span className={styles.firstDataMarkR} />
                                <div className={styles.firstDataInfoTitle}>{insightFirstData.quotTitle}</div>
                                <div className={styles.firstDataInfoBref}>{insightFirstData.quotBref}</div>
                            </a>
                        </div>
                    </Chip>
                    <Chip id="287895" type="recommend" title="财律洞察数据" groupName="财律洞察">
                        <div>
                            {insightData.map((item, index) => {
                                return (
                                    <a href={item.url} key={index} target="_blank" className={styles.insightItem}>
                                        {item.title}
                                    </a>
                                );
                            })}
                        </div>
                    </Chip>

                    <Chip id="260655" type="struct" title="财律洞察查看更多" groupName="财律洞察">
                        <a href={insightMore.moreUrl} target="_blank" className={styles.insightMore}>
                            查看更多
                        </a>
                    </Chip>
                </div>
            </div>
        );
    }
}

export default errorBoundary(Insight);
