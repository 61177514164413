import React from 'react';
import PropTypes from 'prop-types';
import Ad from '@ifeng-fe/ui_pc_ad';
import styles from './index.css';
import Chip from 'Chip';
import ChipEdit from 'ChipEdit';
import transform from 'chipDataTransform';
import Header from './header';
import Navigation from './navigation';
import StockList from './stockList';
import Footer from './footer';
import Global from './global';
import News from './news';
import Live from './live';
import Storm from './storm';
import BankEye from './bankEye';
import Research from './research';
import Invest from './invest';
import Stocks from './stocks';
import Hotspots from './hotspots';
import Newsflash from './newsflash';
import HotList from './hotList';
import Insight from './insight';
import Featured from './featured';
import ClickRank from './clickRank';
import Focus from './focus';
import Wechat from './wechat';
import Cooperation from './cooperation';
import { getAllMarket, getMarketVarieties } from '@src/services/api';

class Layout extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    state = {
        allMarket: [],
        marketVarieties: {},
    };
    timer = null;

    getData = async () => {
        const marketResult = await getAllMarket();

        this.setState({
            allMarket: marketResult,
        });
    };

    async componentDidMount() {
        this.getData();

        const varietiesResult = await getMarketVarieties();

        this.setState({
            marketVarieties: varietiesResult,
        });

        this.timer = setInterval(() => {
            this.getData();
        }, 1000 * 10);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    render() {
        const {
            nav,
            search,
            topAd,
            logo,
            channelAd,
            navigation,
            stockCode,
            production,
            globalHead,
            globalData,
            newsHead,
            newsData,
            stormHead,
            stormBref,
            stormData,
            bankEyeHead,
            bankEyeBref,
            bankEyeData,
            bottomAd,
            footer,
            IE78,
            liveHead,
            researchHead,
            researchBref,
            researchData,
            hotspotsHead,
            hotspotsData,
            newsflashHead,
            newsflashData,
            hotListHead,
            hotListMore,
            insightHead,
            insightData,
            investHead,
            investBref,
            investData,
            insightFirstData,
            insightMore,
            featuredHead,
            featuredData,
            clickRankHead,
            clickRankData,
            focusHead,
            focusImageData,
            focusTextData,
            wechatHead,
            wechatData,
            cooperation,
            leftAd,
            leftAd1,
            leftAd2,
            // leftAd3,
            // leftAd4,
            // leftAd5,
            // leftAd6,
            // leftAd7,
            // leftAd8,
            // leftAd9,
            // leftAd10,
            // leftAd11,
            centerAd1,
            rightAd1,
            rightAd2,
            rightAd3,
            rightAd4,
            floatAd1,
            floatAd2,
            floatAd3,
            floatAd4,
            floatAd5,
            floatAd6,
        } = this.props.content;
        const { allMarket, marketVarieties } = this.state;

        // 头部
        const headerData = {
            nav,
            search,
            topAd,
            logo,
            channelAd,
        };
        // 导航
        const navigationData = {
            nav: navigation,
        };
        // 股票
        const stockBanner = {
            stockCode,
            production,
        };
        // 放眼全球
        const global = {
            globalHead,
            globalData,
        };
        // 财经要闻
        const news = {
            newsHead,
            newsData,
            leftAd2,
        };
        // 直播
        const live = {
            liveHead,
            centerAd1,
        };
        // 风暴眼
        const storm = {
            stormHead,
            stormBref,
            stormData,
        };
        // 银行财眼
        const bankEye = {
            bankEyeHead,
            bankEyeBref,
            bankEyeData,
        };
        // 上市公司研究院
        const research = {
            researchHead,
            researchBref,
            researchData,
        };
        // 投资观察
        const invest = {
            investHead,
            investBref,
            investData,
        };
        // 操作热点
        const hotspots = {
            hotspotsHead,
            insightFirstData,
            hotspotsData,
        };
        // 金融监管快报
        const newsflash = {
            newsflashHead,
            newsflashData,
        };
        // 热榜
        const hotList = {
            hotListHead,
            hotListMore,
        };
        // 财律洞察
        const insight = {
            insightHead,
            insightFirstData,
            insightData,
            insightMore,
        };
        // 特色专栏
        const featured = {
            featuredHead,
            featuredData,
        };
        // 点击排行
        const clickRank = {
            clickRankHead,
            clickRankData,
        };
        // 专题会议
        const focus = {
            focusHead,
            focusImageData,
            focusTextData,
        };
        // 官方微信
        const wechat = {
            wechatHead,
            wechatData,
        };
        // footer
        const footerData = {
            bottomAd,
            footer,
        };

        return (
            <div className={styles.layout}>
                {/* 页头 */}
                <Header content={headerData} IE78={IE78} />
                {/* 导航 */}
                <Navigation content={navigationData} />
                {/* 股票banner */}
                <div className={styles.content}>{allMarket.length > 0 && <StockList allMarket={allMarket} />}</div>
                <div className={styles.content}>
                    <div className={styles.contentLeft}>
                        {/* 放眼全球 */}
                        <Global content={global} />
                        <Ad content={leftAd1} />
                        {/* 财经要闻 */}
                        <News content={news} />
                        <Ad content={leftAd} />
                    </div>
                    <div className={styles.contentCenter}>
                        {/* 直播室 */}
                        <Live content={live} />
                        {/* 风暴眼 */}
                        {/* <Storm content={storm} /> */}
                        {/* 银行财眼 */}
                        <BankEye content={bankEye} />
                        {/* 上市公司研究院 */}
                        <Research content={research} />
                        {/* 投资观察 */}
                        <Invest content={invest} />
                        {/* 股票 */}
                        {/* {allMarket.length > 0 &&
                            Object.keys(marketVarieties).length > 0 && (
                                <Stocks marketVarieties={marketVarieties} allMarket={allMarket} />
                            )} */}
                        {/* 操作热点 */}
                        <Hotspots content={hotspots} />
                        {/* IPO观察哨 */}
                        <Newsflash content={newsflash} />
                    </div>
                    <div className={styles.contentRight}>
                        {/* 热榜 */}
                        <HotList content={hotList} />
                        {/* 财律洞察 */}
                        <Insight content={insight} />
                        <div className={styles.rightAdBox}>
                            <Ad content={rightAd1} />
                        </div>
                        {/* 特色专栏 */}
                        <Featured content={featured} />
                        <div className={styles.rightAdBox}>
                            <Ad content={rightAd2} />
                        </div>
                        <div className={styles.rightAdBox}>
                            <Ad content={rightAd3} />
                        </div>
                        {/* 点击排行 */}
                        <ClickRank content={clickRank} />
                        <div className={styles.rightAdBox}>
                            <Ad content={rightAd4} />
                        </div>
                        {/* 专题会议 */}
                        <Focus content={focus} />
                        {/* 官方微信 */}
                        <Wechat content={wechat} />
                    </div>
                </div>
                <Chip id="222340" type="static" title="底部合作链接" groupName="底部合作链接" content={cooperation}>
                    <Cooperation />
                </Chip>
                <Footer content={footerData} />
                <Ad content={floatAd1} />
                <Ad content={floatAd2} />
                <Ad content={floatAd3} />
                <Ad content={floatAd4} />
                <Ad content={floatAd5} />
                <Ad content={floatAd6} />
                <Ad content={floatAd6} />
                <ChipEdit transform={transform} />
            </div>
        );
    }
}

export default Layout;
