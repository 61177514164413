import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';
import ModuleHead from '../../components/moduleHead';

class Global extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;
        const { globalHead, globalData } = content;

        return (
            <div className={styles.global}>
                <Chip id="260633" type="struct" title="放眼全球标题" groupName="放眼全球" content={globalHead}>
                    <ModuleHead />
                </Chip>
                <Chip id="287893" type="recommend" title="放眼全球手工干预位置" groupName="放眼全球">
                    <div className={styles.globalContent}>
                        <div className={styles.globalItem}>
                            {globalData.slice(0, 3).map((item, index) => {
                                return (
                                    <a
                                        key={index}
                                        className={index === 0 ? styles.globalBoldText : styles.globalThinText}
                                        href={item.url}
                                        target="_blank">
                                        {item.title}
                                    </a>
                                );
                            })}
                        </div>
                        {globalData.length > 3 && (
                            <div className={styles.globalItem}>
                                {globalData.slice(3, 6).map((item, index) => {
                                    return (
                                        <a
                                            key={index}
                                            className={index === 0 ? styles.globalBoldText : styles.globalThinText}
                                            href={item.url}
                                            target="_blank">
                                            {item.title}
                                        </a>
                                    );
                                })}
                            </div>
                        )}
                        {globalData.length > 6 && (
                            <div className={styles.globalItem}>
                                {globalData.slice(6, 9).map((item, index) => {
                                    return (
                                        <a
                                            key={index}
                                            className={index === 0 ? styles.globalBoldText : styles.globalThinText}
                                            href={item.url}
                                            target="_blank">
                                            {item.title}
                                        </a>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </Chip>
            </div>
        );
    }
}

export default errorBoundary(Global);
