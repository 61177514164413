import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';
import ModuleHead from '../../components/moduleHead';

class Wechat extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染组件
     */
    render() {
        const { wechatHead, wechatData } = this.props.content;

        return (
            <div className={styles.wechat}>
                <Chip id="260646" type="struct" title="官方微信标题" groupName="官方微信" content={wechatHead}>
                    <ModuleHead />
                </Chip>
                <Chip id="260653" type="struct" title="官方微信数据" groupName="官方微信" content={wechatHead}>
                    <div className={styles.wechatBox}>
                        {wechatData.map((item, index) => {
                            return (
                                <div key={index} className={styles.wechatItem}>
                                    <img className={styles.wechatQrcode} src={item.thumbnail} />
                                    <div className={styles.wechatText}>
                                        <div className={styles.wechatTitle}>{item.title}</div>
                                        <div className={styles.wechatBref}>{item.bref}</div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </Chip>
            </div>
        );
    }
}

export default errorBoundary(Wechat);
